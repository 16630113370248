<template>
  <div
    class="profile-data-wrapper financial-graphs service-fee"
    v-show="!loading"
  >
    <template
      v-if="
        userPermissions &&
        userPermissions.financial_graphs &&
        userPermissions.financial_graphs.length > 0 &&
        userPermissions.financial_graphs.includes('View')
      "
    >
      <div class="profile-options">
        <div class="row">
          <div class="col">
            <router-link
              class="btn primary-btn"
              :to="{ name: 'FinancialGraphs' }"
              ><font-awesome-icon :icon="['fas', 'arrow-left']"
            /></router-link>
          </div>
          <div class="col flex-grow-1 d-flex justify-content-end">
            <Select2
              id="filter-period"
              :options="filters.period.options"
              v-model="filters.period.value"
              class="select2-box"
              :resetOptions="false"
              @select="getRefoundService()"
            />
          </div>
          <!-- <div class="col">
            <Select2
              id="filter-connect-types"
              v-model="filters.client.value"
              :options="filters.client.options"
              class="select2-box"
              :resetOptions="false"
              :placeholder="`Connect Type`"
              @select="getConnects()"
            />
          </div> -->
        </div>
      </div>
      <!-- <div class="connects-chart equal-col">
        <h2 class="section-heading text-transform-uppercase">
          Service Fee Graph ({{ filters.client.value }})
        </h2>
        <template v-if="false">
          <div
            class="
              no-data-wrap
              d-flex
              align-items-center
              justify-content-center
            "
          >
            <img src="@/assets/images/no-graph.png" alt="no-graph" />
            <div class="desc">No data<br />to show</div>
          </div>
        </template>
        <template v-else>
          <chart-js
            :type="`line`"
            :datasets="connectChart.datasets"
            :labels="connectChart.labels"
            :options="connectChart.options"
          />
        </template>
      </div> -->
      <div class="row grid-1">
       <div class="col">
      <div class="custom-table no-arrows">
        <table id="datatable">
          <thead>
            <tr>
              <th>Time Period</th>
              <th>Refunded Amount($)</th>
             
            </tr>
          </thead>
          <tbody>
            <template v-for="(connect, index) in connectData" :key="index">
              <tr>
                <td>{{ connect.x }}</td>
                <td>{{ Math.round(connect.y) }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      </div>
      </div>
      <div class="profile-footer d-flex justify-content-end">
        <button class="primary-btn" type="button" @click="downloadCsv()">
          <font-awesome-icon :icon="['fa', 'download']" /> Download
        </button>
      </div>
    </template>
    <template
      v-if="
        userPermissions &&
        userPermissions.financial_graphs &&
        (userPermissions.financial_graphs.length === 0 ||
          (userPermissions.financial_graphs.length > 0 &&
            !userPermissions.financial_graphs.includes('View')))
      "
      >Permission Denied.</template
    >
  </div>
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronRight,
  faDownload,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
library.add(faChevronRight, faDownload, faArrowLeft);

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";
import Select2 from "../components/Select2.vue";
import '@vuepic/vue-datepicker/dist/main.css'
import financialRefoundService from "../services/financialRefound.service";
import common from "../mixins/common";

export default {
  components: { Select2, FontAwesomeIcon },
  mixins: [common],
  data() {
    return {
      loading: false,
      filters: {
        period: {
          options: ["Monthly", "Annually"],
          value: "Monthly",
        },
        client: {
          options: [],
          value: "",
        },
      },
      connectData: [],
      connectChart: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        datasets: [],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          hover: { mode: null },
          layout: {
            padding: {
              top: 30,
              bottom: 30,
            },
          },
          plugins: {
            legend: false,
            title: false,
            tooltip: { enabled: true },
            datalabels: false,
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              tricks: {
                reverse: true,
              },
            },
            y: {
              grid: {
                drawBorder: false,
                display: true,
              },
              min: 0,
              ticks: {
                stepSize: 10,
              },
              title: {
                display: true,
                text: "Service Fee",
                font: {
                  color: "#333",
                  weight: "600",
                  size: '18px "Montserrat", sans-serif',
                },
              },
            },
          },
        },
      },
      sideModal: {
        connect: {
          add: {
            show: false,
          },
        },
      },
    };
  },
  async mounted() {
    await this.getRefoundService();
    this.loading = false;
  },
  methods: {
    async getRefoundService() {
      let vm = this;
      const userPermissions = await vm.userPermissions;
      if (
        userPermissions &&
        userPermissions.financial_graphs &&
        userPermissions.financial_graphs.length > 0
      ) {
        try {
          vm.$store.commit("loadingStatus", true);
          const response = await financialRefoundService.get(vm.filters.period.value);

          vm.connectData = response.refunds.slice().reverse();
          //vm.filters.client.options = response.clients;
          //vm.filters.client.value = response.filters.client;

          // const graphData = response.graph_data;
          // const graphDataSets = graphData.datasets;
          // let datasets = [];

          // for (let index = 0; index < graphDataSets.length; index++) {
          //   const element = graphDataSets[index];
          //   datasets.push({
          //     label: element.contactName,
          //     data: element.data,
          //     borderColor: vm.getRandomColor(), //'#6fda45',
          //     tension: 0.1,
          //   });
          // }

          // vm.connectChart.labels = graphData.labels;
          // vm.connectChart.datasets = datasets;
          // console.log(datasets);

          // $("#datatable").DataTable().destroy();

          var dT = $("#datatable").DataTable();
          dT.destroy();

          vm.$nextTick(function () {
            $("#datatable").DataTable({
              dom: "Bfrtip",
              buttons: [
                {
                  extend: "csv",
                  filename: "Refunds "+vm.filters.period.value,
                  extension: ".csv",
                },
              ],
              iDisplayLength: 25,
            });
            $(".dt-buttons, .dataTables_filter").hide();
          });
          vm.$store.commit("loadingStatus", false);
        } catch (error) {
          const message = vm.errorMessage(error);
          vm.toastMessage(message, "error");
          vm.$store.commit("loadingStatus", false);
        }
      }
    },
    downloadCsv: async function () {
      $(".buttons-csv").click();
    },
    closeSideModal() {
      document.body.classList.remove("lmodal-open");
      setTimeout(() => {
        this.sideModal.connect.add.show = false;
      }, 300);
    },
    async openSideModal(action) {
      let vm = this;

      await vm.$store.dispatch("getUserDetails");

      if (
        vm.userPermissions &&
        vm.userPermissions.financial_graphs &&
        vm.userPermissions.financial_graphs.length > 0 &&
        vm.userPermissions.financial_graphs.includes("View")
      ) {
        if (
          action === "addConnect" &&
          vm.userPermissions.financial_graphs.includes("Add")
        ) {
          vm.sideModal.connect.add.show = true;
        }
        setTimeout(() => {
          document.body.classList.add("lmodal-open");
        }, 300);
      }

    },
    getRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
  },
  watch: {
    userPermissions: {
      handler: function () {
        this.getRefoundService();
      },
      deep: true,
    },
  },
};
</script>
